.header {
    position: fixed;
    width: 100%;
    backdrop-filter: blur(18px);
    padding: 18px 125px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9999999;

    &__right {
        display: flex;
        align-items: center;
        gap: 50px;
    }

    &__btn {
        width: 214px;
        font-weight: 400;
        font-size: 14px;
        padding: 14px 20px 14px 20px;
    }

    &__titles {
        display: flex;
        align-items: center;
        gap: 30px;
        p {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: rem(14);
            font-family: TT FORS, sans-serif;
            cursor: pointer;
            padding: 15px 10px;
            //&.isActive {
            //    display: flex;
            //    align-items: center;
            //    justify-content: center;
            //    border-radius: 10px;
            //    background: rgba(255, 255, 255, 0.07);
            //    padding: 15px 10px;
            //}
        }
    }

    &__logo {
        cursor: pointer;
        width: 185px;
        height: 48px;
    }
}

.hamburger {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    span {
        width: 15px;
        height: 2px;
        background: #ffffff;
    }

    &__close {
        display: flex;
        align-items: center;
        width: 30px;
        height: 30px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: 20px;
            height: 2px;
            transform: rotate(-45deg);
            background: #ffffff;
        }
        &:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 2px;
            transform: rotate(45deg);
            background: #ffffff;
        }
    }
}