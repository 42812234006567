.forms {
    position: relative;
    height: 100vh;
    width: 100%;
    color: #ffffff;
    margin-top: 50px;

    .background {
        background: radial-gradient(38.56% 73.15% at 50% 50%, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.66) 100%), lightgray 50%;
        filter: blur(23.5px);
    }

    &__socials {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 5px;
        img {
            width: 45px;
            height: 45px;
            cursor: pointer;
        }
    }

    button {
        font-size: 17px;
        font-family: TT FORS, sans-serif;
        margin-top: 40px;
    }

    &__block {
        min-width: 440px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        background: #191919;
        //margin-top: 500px;

        &-title {
            font-size: 18px;
            margin-bottom: 15px;
            span {
                color: var(--Gradic);
                background-clip: text;
                background: var(--Gradic, linear-gradient(90deg, #467FFF 0%, #A414FF 100.93%));
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &-desc{
            font-family: TT FORS, sans-serif;
            text-align: center;
            opacity: .5;
            margin-bottom: 30px;
        }

        &-inputs {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 10px;

            &-item {
                padding: 2px;
                background: var(--Gradic, linear-gradient(90deg, #467FFF 0%, #A414FF 100.93%));
                width: 100%;

                div {
                    background: #191919;
                }
            }
        }
    }
}

//@media screen and (max-width: 800px) {
//    .forms {
//        &__block {
//            top: 220%;
//        }
//    }
//}