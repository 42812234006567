.project {
    display: flex;
    justify-content: center;
    padding: 125px 42px;
    background-color: #000;
    color: #ffffff;
    gap: 60px;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-desc {
            font-family: TT FORS, sans-serif;
            width: 500px;
            height: 330px;
            text-align: center;
        }
        &-btn {
            width: 352px;
            height: 48px;
        }
    }

    &__title {
        margin-top: rem(40);
        width: 545px;
        margin-bottom: 46px;
    }

    &__img {
        width: 340px;
    }
}