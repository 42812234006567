@function rem($pixels, $context: $browser-context) {
    @return #{calc($pixels/$context)}rem;
}

@mixin transition($property: 0.3s) {
    -webkit-transition: all $property ease;
    -moz-transition: all $property ease;
    -ms-transition: all $property ease;
    -o-transition: all $property ease;
    transition: all $property ease;
}

@mixin transform($property) {
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
    transform: $property;
}

@mixin placeholder() {
    ::-moz-placeholder {
        @content;
    }

    ::-webkit-input-placeholder {
        @content;
    }

    ::-ms-input-placeholder {
        @content;
    }

    :-ms-input-placeholder {
        @content;
    }

    ::placeholder {
        @content;
    }
}

@mixin hide() {
    visibility: hidden;
    opacity: 0;
}

@mixin show() {
    visibility: visible;
    opacity: 1;
}

@mixin input-placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::placeholder {
        @content;
    }
}
