.androids {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
    &__content {
        padding: 50px 0 500px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: linear-gradient(180deg, rgba(164, 20, 255, 0.00) 0%, rgba(164, 20, 255, 0.17) 14.06%, rgba(164, 20, 255, 0.12) 85.94%, rgba(164, 20, 255, 0.00) 100%);
    }
}

.androids {
    display: flex;
    padding: 300px 0 400px 0;
    background: linear-gradient(180deg, rgba(164, 20, 255, 0.00) 0%, rgba(164, 20, 255, 0.17) 14.06%, rgba(164, 20, 255, 0.12) 85.94%, rgba(164, 20, 255, 0.00) 100%);

    &__title {
        color: #F0F;
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
    }

    &__first {
        width: 1105px;
        position: relative;
        margin-top: 220px;

        &-img {
            position: absolute;
            left: 5%;
            top: -42%;
            width: 317px;
            //height: 300px;
        }

        &-title {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: -30px;
            position: absolute;
            left: 45%;

            svg {
                margin-top: -30px;
            }
            h1 {
                text-wrap: nowrap;
                margin-left: 40px;
                line-height: 24px;
                font-weight: 400;
                font-size: 20px;
                color: #FFFFFF;
            }
        }

        &-desc {
            left: 45%;
            top: 15%;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            z-index: 100;
            position: absolute;
            color: #ffffff;
        }
    }

    &__second {

        width: 1105px;
        position: relative;
        margin-top: 220px;

        &-img {
            top: -42%;
            left: 62%;
            width: 317px;
            position: absolute;
        }

        &-title {
            display: flex;
            align-items: center;
            gap: 10px;
            position: absolute;
            left: 2%;

            h1 {
                text-wrap: nowrap;

                margin-left: 60px;
                line-height: 24px;
                font-weight: 400;
                font-size: 20px;
                color: #FFFFFF;
            }
        }

        &-desc {
            width: 528px;
            left: 3%;
            top: 42%;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            z-index: 100;
            position: absolute;
            color: #ffffff;
        }
    }

    &-second {
        margin-top: rem(-100);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: rem(40);
        margin-bottom: rem(200);

        &-title {
            margin-top: -230px;
            display: flex;
            font-size: rem(40);
            line-height: rem(45);
            flex-direction: column;
            align-items: center;
            color: #ffffff;
            span {
                color: #FF00FF
            }
        }

        img {
            width: 80%;
        }
    }
}

