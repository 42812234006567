@media screen and (min-width: 3500px) {
    body {
        overflow-x: hidden;
    }

    .header {
        padding: rem(40) rem(400);

        &__logo {
            height: rem(100);
            width: rem(500);
        }

        &__titles {
            p {
                line-height: rem(28);
                font-size: rem(28);
            }
        }

        button {
            font-size: rem(32);
            width: rem(400);
            height: rem(80);
        }
    }
    .introduction {
        &__content {
            &-left {
                h1 {
                    font-size: rem(80);
                    line-height: rem(80);
                }

                p {
                    line-height: rem(38);
                    font-size: rem(28);
                }
            }
        }

        &__socials {
            img {
                width: rem(90);
                height: rem(90);
            }
        }
    }

    .project {
        &__img {
            width: rem(600);
        }

        &__content {
            img {
                width: rem(1200);
            }

            p {
                width: rem(900);
                font-size: rem(28);
                line-height: rem(28);
            }
        }
    }

    .games {
        margin-top: rem(300);
        padding-bottom: rem(400);
        transform: scale(1.3);

        &__first, &__second, &__third, &__fourth {
            &-background {
                width: 40%;
            }
        }

        &__first {
            &-item {
                img {
                    left: unset;
                    right: -12%;
                    width: rem(550);
                    height: rem(700);
                    top: unset;
                    bottom: -25%;
                }

                &-text {
                    width: 80%;
                }
            }
        }

        &__second {
            &-item {
                img {
                    top: unset;
                    bottom: -10%;
                    left: -20%;
                    width: rem(580);
                    height: rem(450);
                }

                &-text {
                    width: 80%;
                }
            }
        }

        &__third {
            margin-top: rem(230);

            &-item {
                img {
                    width: rem(450);
                    height: rem(700);
                    left: unset;
                    right: -10%;
                }

                &-text {
                    width: 80%;
                }
            }
        }

        &__fourth {
            margin-top: rem(230);

            &-item {
                img {
                    width: rem(450);
                    height: rem(700);
                }

                &-text {
                    width: 80%;
                }
            }
        }

        h1 {
            line-height: rem(58);
            font-size: rem(60);
        }

        p {
            line-height: rem(30);
            font-size: rem(28);
        }
    }

    .androids {
        padding: rem(400) 0;

        &__title {
            font-size: rem(80);
        }

        &__first, &__second {
            margin-top: rem(400);
            transform: scale(2);
        }

        &__first {
            margin-top: rem(500);
        }

        &__image {
            &-bottom {
                width: 80%;
            }
        }
    }

    .forms {
        &__socials {
            img {
                width: rem(90);
                height: rem(90);
            }
        }

        &__block {
            width: 30%;
            padding: rem(60);

            &-title {
                font-size: rem(60);
            }

            &-desc {
                line-height: rem(30);
                font-size: rem(28);
            }

            &-inputs {
                gap: rem(20);

                &-item {
                    padding: rem(4);

                    .dropdown__block {
                        font-size: rem(28);

                        .react-select-prefix__value-container {
                            padding: rem(0) rem(10);
                        }
                    }
                }

                input {
                    font-size: rem(28);
                    height: rem(100);
                }
            }

            button {
                font-weight: 400;
                font-size: rem(40);
                height: rem(80);
            }
        }
    }

    .androids {
        &-second {
            gap: rem(80);
            margin-top: rem(150);
            &-title {
                margin-top: rem(-500);
                font-size: rem(80);
                line-height: rem(100);
            }
        }
    }
}


@media screen and (min-width: 2500px) and (max-width: 3499px) {
    body {
        overflow-x: hidden;
    }
    .header {
        padding: rem(40) rem(300);

        &__logo {
            height: rem(80);
            width: rem(400);
        }

        &__titles {
            p {
                line-height: rem(28);
                font-size: rem(28);
            }
        }

        button {
            font-size: rem(28);
            width: rem(400);
            height: rem(70);
        }
    }
    .introduction {
        &__content {
            &-left {
                h1 {
                    font-size: rem(80);
                    line-height: rem(80);
                }

                p {
                    line-height: rem(38);
                    font-size: rem(28);
                }
            }
        }

        &__socials {
            img {
                width: rem(90);
                height: rem(90);
            }
        }
    }

    .project {
        &__img {
            width: rem(500);
        }

        &__content {
            img {
                width: rem(900);
            }

            p {
                width: rem(800);
                font-size: rem(28);
                line-height: rem(32);
            }
        }
    }

    .games {
        margin-top: rem(400);
        padding-bottom: rem(200);
        transform: scale(1.3);

        &__first, &__second, &__third, &__fourth {
            &-background {
                width: 50%;
            }
        }

        &__first {
            &-item {
                img {
                    left: unset;
                    right: -12%;
                    width: rem(550);
                    height: rem(700);
                    top: unset;
                    bottom: -25%;
                }
            }
        }

        &__second {
            &-item {
                img {
                    top: unset;
                    bottom: -10%;
                    left: -20%;
                    width: rem(580);
                    height: rem(450);
                }

                &-text {
                    width: 70%;
                }
            }
        }

        &__third {
            margin-top: rem(230);

            &-item {
                img {
                    width: rem(450);
                    height: rem(700);
                    left: unset;
                    right: -10%;
                }

                &-text {
                    width: 80%;
                }
            }
        }

        &__fourth {
            margin-top: rem(230);

            &-item {
                img {
                    width: rem(450);
                    height: rem(700);
                }

                &-text {
                    width: 80%;
                }
            }
        }

        h1 {
            line-height: rem(58);
            font-size: rem(60);
        }

        p {
            line-height: rem(30);
            font-size: rem(28);
        }
    }

    .androids {
        padding: rem(400) 0;

        &__title {
            font-size: rem(80);
        }

        &__first, &__second {
            margin-top: rem(400);
            transform: scale(2);
        }

        &__first {
            margin-top: rem(500);
        }

        &__image {
            &-bottom {
                width: 80%;
            }
        }
    }

    .forms {
        &__socials {
            img {
                width: rem(90);
                height: rem(90);
            }
        }

        &__block {
            width: 40%;
            padding: rem(60);

            &-title {
                font-size: rem(60);
            }

            &-desc {
                line-height: rem(30);
                font-size: rem(28);
            }

            &-inputs {
                gap: rem(20);

                &-item {
                    padding: rem(4);

                    .dropdown__block {
                        font-size: rem(28);

                        .react-select-prefix__value-container {
                            padding: rem(0) rem(10);
                        }
                    }
                }

                input {
                    font-size: rem(28);
                    height: rem(100);
                }
            }

            button {
                font-weight: 400;
                font-size: rem(40);
                height: rem(80);
            }
        }
    }

    .footer {
        padding-top: rem(30);
        padding-bottom: rem(30);

        &__text {
            font-size: rem(28);
        }

        p {
            font-size: rem(28);
        }
    }

    .androids {
        &-second {
            gap: rem(80);
            margin-top: rem(150);
            &-title {
                margin-top: rem(-400);
                font-size: rem(68);
                line-height: rem(100);
            }
        }
    }
}

@media screen and (max-width: 1450px) {
    .project {
        gap: 30px;

        &__content {
            width: 400px;

            &-desc {
                width: 350px;
                font-size: 14px !important;
                line-height: 20px !important;
            }
        }

        &__title {
            width: 300px;
            margin-bottom: 20px;
        }
    }

    .games {
        &__title {
            margin-bottom: 150px;
        }

        &__first, &__second, &__third, &__fourth {
            &-background {
                width: 80%;
            }
        }

        &__first {
            &-item {
                img {
                    left: 70%;
                    width: 430px;
                    height: 430px;
                    top: -80%;
                }
            }
        }

        &__second {
            margin-top: rem(120);

            &-item {
                img {
                    width: 410px;
                    height: 310px;
                    left: -11%;
                    top: -22%;
                }
            }
        }

        &__third {
            margin-top: rem(140);

            &-item {
                &-text {
                    width: 80%;
                }

                img {
                    left: 75%;
                    top: -70%;
                    width: 258px;
                    height: 438px;
                }
            }
        }

        &__fourth {
            margin-top: rem(160);

            &-item {
                &-text {
                    width: 75%;
                }

                img {
                    left: -3%;
                    top: -80%;
                    width: 239px;
                    height: 419px;
                }
            }
        }
    }

    .androids {
        &-second {
            &-title {
                margin-top: rem(-200);
                font-size: rem(32);
            }
        }
    }
}

@media screen and (max-width: 1230px) {
    .introduction {
        &__content {
            top: 60%;
        }
    }

    .header {
        padding: 18px 50px;
    }

    .project {
        gap: 30px;

        &__content {
            width: 400px;

            &-desc {
                width: 350px;
                font-size: 14px !important;
                line-height: 20px !important;
            }
        }

        &__title {
            width: 300px;
            margin-bottom: 20px;
        }
    }

    .games {
        overflow: hidden;

        &__title {
            margin-bottom: 150px;
        }

        &__first, &__second, &__third, &__fourth {
            &-background {
                width: 80%;
            }

            &-item {
                &-text {
                    width: 75%;

                    h1 {
                        font-size: rem(32);
                        line-height: rem(34);
                    }

                    p {
                        font-size: rem(15);
                        line-height: rem(18);
                    }
                }

                img {
                    width: 400px;
                    height: 470px;
                    top: -70%;
                }
            }
        }

        &__first {
            &-item {
                img {
                    width: 400px;
                    height: 470px;
                    top: -70%;
                }
            }
        }

        &__second {
            margin-top: rem(120);

            &-item {
                img {
                    width: 350px;
                    height: 280px;
                    left: -20%;
                    top: -22%;
                }
            }
        }

        &__third {
            margin-top: rem(90);

            &-item {
                img {
                    top: -50%;
                    width: 228px !important;
                    height: 428px;
                }
            }
        }

        &__fourth {
            margin-top: rem(160);

            &-item {
                img {

                    left: -10%;
                    top: -70%;
                    width: 249px;
                    height: 429px;
                }
            }
        }
    }

    .androids {

        align-items: center;
        justify-content: center;

        &__first {
            width: 960px;
            display: flex;
            justify-content: center;

            &-title {
                top: unset;
                margin-top: -50px;

                svg {
                    margin-top: 0;
                }

                h1 {
                    margin-top: 0;
                }
            }

            &-frame {
                svg {
                    width: 960px;
                }
            }

            &-img {
                top: unset;
                left: 3%;
                bottom: 10%;
            }

            &-desc {
                left: 44%;
                top: 20%;
                font-size: 14px;
            }
        }

        &__second {
            width: 960px;
            display: flex;
            justify-content: center;

            &-frame {
                svg {
                    width: 960px;
                }
            }

            &-img {
                top: unset;
                bottom: 10%;
            }

            &-desc {
                font-size: 14px;
            }
        }

        &-mobile-2 {
            padding: 0 10px;
            width: 100%;
        }
    }

    .androids {
        &-second {
            &-title {
                margin-top: rem(-180);
                font-size: rem(28);
            }
        }
    }
}

@media screen and (max-width: 1030px) {
    .project {
        &__img {
            width: 180px;
        }
    }

    .androids {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &__first, &__second {
            width: 475px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title, &-desc {
                position: unset;
            }

            &-title {
                display: flex;
                align-items: center;
                margin-top: 20px;

                svg {
                    margin-top: 0;
                }

                h1 {
                    margin-top: 0;
                    margin-left: 0;
                }
            }

            &-img {
                z-index: 10;
                margin-bottom: -20px;
                margin-left: -15px;
                height: 310px;
                bottom: 52%;
                left: unset;
                top: unset;
            }

            &-frame {
                height: 200px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 300px;
                    height: 100px;
                    //left: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: scale(2);
                }
            }
        }

        &-mobile-2 {
            padding: 0 10px;
            width: 100%;
        }
    }
    .androids {
        &-second {
            &-title {
                margin-top: rem(-150);
                font-size: rem(24);
            }
        }
    }
}


@media screen and (max-width: 730px) {
    .header {
        padding: 18px 50px;
    }

    .introduction {

        &__content {
            top: 53%;
            padding-left: 50px;
        }

        &__title {
            font-size: 36px;
        }
    }

    .project-mobile__img {
        width: 100%;
    }

    .games {
        padding: 226px 0 171px 0;

        &__title {

            font-size: 25px;
            line-height: 30px;
        }

        &__first, &__second, &__third, &__fourth {
            margin-top: 60px;

            &-background {
                padding: 5px 0;
                width: 100%;
            }

            &-item {
                padding: 20px;

                &-text {
                    width: 100%;
                    z-index: 10;

                    h1 {
                        line-height: 30px;
                        z-index: 10;
                    }

                    p {
                        z-index: 10;
                    }
                ;
                }

                img {
                    z-index: 1;
                    left: 40%;
                    top: -160%;
                    width: 305px;
                    height: 387px;
                }
            }
        }

        &__second {
            margin-top: 200px;

            &-item {
                img {
                    z-index: 1;
                    left: -10%;
                    top: -100%;
                    width: 372px;
                    height: 276px;
                }
            }
        }

        &__third {
            margin-top: 200px;

            &-item {
                img {
                    z-index: 1;
                    left: 55%;
                    top: -120%;
                    width: 338px;
                    height: 338px;
                }
            }
        }

        &__fourth {
            margin-top: 200px;

            &-item {
                img {
                    z-index: 1;
                    left: 10%;
                    top: -100%;
                    width: 399px;
                    height: 399px;
                }
            }
        }
    }

    .androids {
        padding: 0;
        margin-top: -100px !important;
        padding-top: 300px;
        overflow: hidden;
        width: 100%;

        &__title {
            font-size: 25px;
            line-height: 30px;
        }

        &__first, &__second {
            margin-top: 120px;
            padding: 15px;
            width: 100%;

            &-title {
                width: 100%;

                h1 {
                    width: 100%;
                    text-wrap: unset;
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            &-desc {
                width: unset;
                margin-top: 10px;
            }

            &-img {
                z-index: 10;
                top: unset;
                bottom: unset;
                margin-top: -110px;
                height: 300px;
                width: 250px;
            }

            &-frame {
                svg {
                    transform: scale(2);
                }
            }
        }
    }

    .androids {
        &-second {
            margin-top: rem(150);
            &-title {
                margin-top: rem(-150);
                font-size: rem(28);
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .header {
        padding: 18px 50px;
    }

    .introduction {

        &__content {
            bottom: 10%;
            top: unset;
            padding-left: 20px;
            padding-right: 20px;
        }

        &__title {
            line-height: 30px;
            font-size: 36px;
        }
    }

    .project {
        padding: 0 42px;
    }
    .project-mobile__img {
        width: 100%;
    }

    .games {

        overflow: hidden;
        padding: 226px 0 0 0;

        &__title {

            font-size: 25px;
            line-height: 30px;
        }

        &__first, &__second, &__third, &__fourth {
            margin-top: 140px;

            &-background {
                padding: 5px 0;
                width: 100%;
            }

            &-item {
                padding: 20px;

                &-text {
                    width: 100%;
                    z-index: 10;

                    h1 {
                        font-size: rem(26);
                        line-height: 30px;
                        z-index: 10;
                    }

                    p {
                        z-index: 10;
                    }
                ;
                }

                img {
                    z-index: 1;
                    right: 0;
                    top: -160%;
                    width: 305px;
                    height: 387px;
                }
            }
        }

        &__second {
            margin-top: 200px;

            &-item {
                img {
                    top: unset;
                    z-index: 1;
                    left: -30%;
                    bottom: 60%;
                    width: 372px;
                    height: 276px;
                }
            }
        }

        &__third {
            margin-top: 230px;

            &-item {
                img {
                    top: unset;
                    left: unset;
                    z-index: 1;
                    bottom: 60%;
                    right: 0;
                    width: 338px;
                    height: 338px;
                }
            }
        }

        &__fourth {
            margin-top: 200px;

            &-item {
                img {
                    bottom: 1%;
                    z-index: 1;
                    left: 0;
                    top: unset;
                    width: 259px;
                    height: 389px;
                }
            }
        }
    }

    .androids {
        padding: 0;
        margin-top: -100px !important;
        padding-top: 300px;
        overflow: hidden;
        width: 100%;

        &__title {
            font-size: 25px;
            line-height: 30px;
        }

        &__first, &__second {
            margin-top: 120px;
            padding: 15px;
            width: 100%;

            &-title {

                h1 {
                    text-wrap: unset;
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            &-desc {
                width: unset;
                margin-top: 10px;
            }

            &-img {
                z-index: 10;
                top: unset;
                bottom: unset;
                margin-top: -170px;
                height: 300px;
                width: 250px;
            }

            &-frame {
                svg {
                    transform: scale(1.6);
                }
            }
        }

        &-second {
            margin-top: 100px;
        }
    }

    .androids {
        &-second {
            width: 100%;
            display: flex;
            align-items: center;

            &-title {
                text-align: center;
                display: flex;
                align-items: center;
                margin-top: rem(-100);
                font-size: rem(28);
            }
        }
    }


    .forms__block-title {
        font-size: 16px;
    }
    .introduction {
        &__title {
            font-size: 26px;
        }
    }

    .forms__block {
        min-width: 350px;
    }
}