.feedback {
	background: #1E1F24;

	&__logo {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: rem(75);
		border-radius: rem(10);
		margin: auto;
		overflow: hidden;
	}

	&__form {
		&-title {
			padding: rem(20) 0;
		}

		&-form {
			.form-group {
				margin-bottom: 1rem;
			}
		}
	}

	&__faq {
		&-title {
			padding: rem(50) 0 rem(20);
		}

		&-items {
			background-color: #2E2F37;
			border-radius: rem(4);
			padding: rem(13) rem(14);
			margin-bottom: rem(15);
			cursor: pointer;
			gap: 0;

			&.active {
				.feedback__faq-desc {
					height: auto;
					opacity: 0.5;
					visibility: visible;
					padding-top: rem(15);
				}

				svg {
					transform: rotate(-180deg);
				}
			}
		}

		&-top svg, &-desc {
			transition: all 0.3s ease-in-out;
		}

		&-desc {
			height: 0;
			opacity: 0;
			visibility: hidden;
		}
	}
}
