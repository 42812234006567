.introduction {
    height: 100vh;
    width: 100%;
    color: #ffffff;

    &__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 125px;
        padding-right: 50px;
        position: absolute;
        top: 65%;
        z-index: 1;   background: none;

        &-left {
            display: flex;
            flex-direction: column;
            gap: 10px;
            background: none;
        }
    }

    &__title {
        line-height: 49px;
        font-size: 45px;
        text-transform: uppercase;
        background: none;
    }

    &__desc {
        width: 33.75rem;
        font-family: TT FORS, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background: none;
    }
    &__btn {
        margin-top: 30px;
        width: 274px;
        height: 48px;
        background: none;
    }

    &__socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        img {
            width: 45px;
            height: 45px;
            cursor: pointer;
        }
    }
}

.menu {
    position: fixed;
    margin-top: 83px;
    padding: 30px 30px  113px 30px;
    width: 100%;
    height: 100%;
    z-index: 30;
    background: black;
    transition: 1s all;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        p {
            border-radius: 20px;
            text-align: center;
            width: 100%;
            background: black;
            padding: 20px 0;
            background: rgba(255, 255, 255, 0.05);
        }
    }
    &__icons {
        img {
            cursor: pointer;
            width: 70px;
            height: 70px;
        }
    }
}

.forms {
    scroll-margin: 1500px;
}