.questions {
    position: relative;
    padding: rem(30) rem(20);
    color: #FFFFFF;

    &-top__btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        color:  #3582F6;
    }

    &__items {
        margin-top: rem(30);
        display: flex;
        flex-direction: column;
        gap: rem(20);
        border-bottom: rem(1) solid rgba(255, 255, 255, 0.1);
        padding-bottom: rem(60);
		position: relative;

        &-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-title {
            font-size: rem(20);
        }

        &-desc {
            font-size: rem(14);
            opacity: .5;
        }

        &-last {
            border-bottom: none;
        }

        &-btns {
            display: flex;
            align-items: center;
            gap: rem(10);
        }

		&-date {
			position: absolute;
			right: 0;
			bottom: rem(30);
			font-size: rem(14);
		}
    }

    &-btn {
        display: flex;
        justify-content: center;
        margin-top: rem(30);
        width: rem(300);
    }

    &-edit {
        padding: rem(30);
        color: #FFFFFF;

        &__title {
            font-size: rem(22);
            margin-bottom: rem(20);
        }
    }
}
