.games {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 125px 42px 0 42px;
    color: #FFFFFF;
    background-color: #000;
    &__image {
        width: 1052px;
        &-bottom {
            width: 1281px;
        }
    }
}

.games-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.games {
    &__title {
        text-align: center;
        font-weight: 400;
        font-size: rem(40);
        line-height: rem(50);
        span {
            color: #F0F
        }

        margin-bottom: rem(250);
    }

    &__first {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #FFFFFF;
        height: 100%;

        &-background {
            width: 60%;
            padding: 5px;
            background: linear-gradient(240deg, #D406D7 27.06%, #0094FF 117.42%);
        }

        &-item {
            position: relative;
            padding: rem(38);
            background: black;

            &-text {
                width: 65%;
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: rem(20);
                h1 {
                    align-self: start;
                    font-weight: 400;
                    font-size: rem(40);
                    line-height: rem(38);
                    span {
                        color: #F0F
                    }
                }

                p {
                    font-weight: 400;
                    font-size: rem(16);
                    line-height: rem(18);
                }

            }
            img {
                position: absolute;
                width: 503px;
                height: 641px;
                left: 65%;
                top: -120%;
            }
        }
    }


    &__second {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #FFFFFF;
        height: 100%;
        margin-top: rem(200);

        &-background {
            width: 65%;
            padding: 5px;
            background: linear-gradient(258deg, #0AFE0B -0.73%, #FFF624 100.77%);
        }

        &-item {
            display: flex;
            justify-content: end;
            position: relative;
            padding: rem(38);
            background: black;

            &-text {
                width: 65%;
                display: flex;
                align-items: end;
                flex-direction: column;
                gap: rem(20);
                h1 {
                    align-self: start;
                    font-weight: 400;
                    font-size: rem(40);
                    line-height: rem(38);
                    span {
                        color: #70D584
                    }
                }

                p {
                    font-weight: 400;
                    font-size: rem(16);
                    line-height: rem(18);
                }

            }
            img {
                position: absolute;
                width: 494px;
                height: 367px;
                left: -20%;
                top: -40%;
            }
        }
    }

    &__third {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #FFFFFF;
        height: 100%;
        margin-top: rem(222);

        &-background {
            width: 60%;
            padding: 5px;
            background: linear-gradient(240deg, #D406D7 27.06%, #0094FF 117.42%);
        }

        &-item {
            position: relative;
            padding: rem(38);
            background: black;

            &-text {
                width: 65%;
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: rem(20);
                h1 {
                    align-self: start;
                    font-weight: 400;
                    font-size: rem(40);
                    line-height: rem(38);
                    span {
                        color: #F0F
                    }
                }

                p {
                    font-weight: 400;
                    font-size: rem(16);
                    line-height: rem(18);
                }

            }
            img {
                position: absolute;
                width: 321px;
                height: 521px;
                left: 65%;
                top: -95%;
            }
        }
    }

    &__fourth {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #FFFFFF;
        height: 100%;
        margin-top: rem(200);
        //margin-bottom: rem(50);

        &-background {
            width: 60%;
            padding: 5px;
            background: linear-gradient(258deg, #DF0275 -0.73%, #FF6B00 100.77%);
        }

        &-item {
            display: flex;
            justify-content: end;
            position: relative;
            padding: rem(38);
            background: black;

            &-text {
                width: 65%;
                display: flex;
                align-items: end;
                flex-direction: column;
                gap: rem(20);
                h1 {
                    align-self: start;
                    font-weight: 400;
                    font-size: rem(40);
                    line-height: rem(38);
                    span {
                        color: #FF5858
                    }
                }

                p {
                    font-weight: 400;
                    font-size: rem(16);
                    line-height: rem(18);
                }

            }
            img {
                position: absolute;
                width: 350px;
                height: 526px;
                left: -15%;
                top: -70%;
            }
        }
    }
}

