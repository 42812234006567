.btn {
	font-size: rem(14);
	line-height: rem(20);
	color: $white;
	position: relative;
	//width: 100%;
	background-color: #2E2F37;
	border-radius: rem(100);
	display: flex;
	align-items: center;
	justify-content: space-between;

	.spinner {
		width: rem(30);
		height: rem(30);
		margin: rem(-15) 0 0 rem(-15);
		circle {
			stroke: $white;
		}
	}

	@include transition();

	&.btn-dropdown {
		position: relative;
		padding: rem(10) rem(15);
		gap: rem(10);

		&:after {
			content: url('data:image/svg+xml,<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g opacity="0.5"><path d="M5.83337 7.5L10.8334 12.5L15.8334 7.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g></svg>');
			width: rem(20);
			height: rem(20);
			font-size: rem(20);
		}
	}

	&.btn-icon {
		background-color: transparent;
		border: none;
		padding: 0;

		&:hover,
		&:focus {
			color: $white;
		}
	}

	&.btn-icon + &.btn-icon {
		margin-left: rem(24);
	}

	&.btn-primary {
		background: linear-gradient(90deg, #4A80EE 0%, #1F48A5 100%);
		border-radius: rem(4);
		border: none;
		padding: rem(13) rem(20);
		font-size: rem(16);

		&:hover,
		&:focus {
			background: linear-gradient(90deg, #4A80EE 0%, #1F48A5 100%);
			color: $white;
		}

		&:disabled {
			opacity: 0.5;
		}
	}

	&.btn-primary2 {
		border: 1px solid rgba(53, 130, 246, 0.20);
		background: rgba(53, 130, 246, 0.20);
		border-radius: rem(4);
		padding: rem(8) rem(12);
		font-size: rem(14);

		&:hover,
		&:focus {
			color: $white;
		}

		&:disabled {
			opacity: 0.5;
		}
	}

	&.btn-primary-tinned {
		//background: $tinned-primary;
		border-radius: rem(4);
		border: none;
		padding-top: rem(10);
		padding-bottom: rem(10);
		padding-left: rem(20);
		padding-right: rem(20);
		color: $primary-color !important;
		border: none;
		font: {
			family: "Open Sans SemiBold";
			size: rem(16);
		}
		&:hover,
		&:focus {
			//background: darken($tinned-primary, 10);
		}
		&:disabled {
			opacity: 0.5;
		}
	}

	&.btn-primary-transparent {
		background: transparent;
		border-radius: rem(4);
		padding: rem(10) rem(20);
		color: $primary-color !important;
		border: rem(2) solid $primary-color;
		font-size: rem(16);

		&:hover,
		&:focus {
			//background: darken($primary-color, 10);
			color: $white !important;
			border-color: transparent;
		}
		&:disabled {
			opacity: 0.5;
		}
	}
	i {
		@include transition();
	}

	&.left {
		text-align: left;
	}

	& + & {
		margin-left: rem(15);
	}
	&.btn-blue-transparent {
		border: none;
		background: transparent;
		color: $secondary-color;
		margin: 0;
		padding: 0;
		font-size: rem(14);

		&:hover,
		&:focus {
			background: transparent;
		}
	}
	&.btn-blue {
		background-color: $secondary-color;
		border-radius: rem(4);
		border: none;
		padding: rem(10) rem(20);
		font-size: rem(14);

		&:hover,
		&:focus {
			background: darken($secondary-color, 10);
			color: $white;
		}
		&:disabled {
			opacity: 0.5;
		}
	}
	&.btn-blue-white {
		background: $white;
		border-radius: rem(4);
		border: rem(2) solid $secondary-color;
		padding-top: rem(14);
		padding-bottom: rem(14);
		color: $secondary-color;
		font-size: rem(18);

		&:hover,
		&:focus {
			background: darken($white, 10);
			color: $secondary-color;
		}
		&:disabled {
			opacity: 0.5;
		}
	}
	&.btn-gray {
		border: none;
		border-radius: rem(8);
		background: $gray;
		color: $black;
		padding: rem(9) rem(24);
		font-size: rem(14);

		&:hover,
		&:focus {
			background: rgba($color: $black, $alpha: 0.2);
		}
	}
	&.btn-gray-transparent {
		background: transparent;
		border-radius: rem(4);
		padding: rem(10) rem(20);
		border: rem(2) solid $black;
		color: #000000;
		opacity: 0.4;
		font: {
			family: "PT Sans Bold";
			size: rem(14);
		}
		&:hover,
		&:focus {
			background: rgba($color: $black, $alpha: 0.2);
		}
		&:disabled {
			opacity: 0.5;
		}
	}
	&.btn-gold {
		display: inline-block;
		width: auto;
		border: rem(2) solid $gold;
		border-radius: rem(100);
		background: transparent;
		color: $black;
		padding: rem(10) rem(40);
		font: {
			family: "Roboto";
			size: rem(14);
		}
		&:hover,
		&:focus {
			background: $gray;
		}
	}
	&.btn-cancel {
		display: inline-block;
		width: auto;
		border: rem(2) solid rgba($color: #000000, $alpha: 0.2);
		border-radius: rem(100);
		background: transparent;
		color: $black;
		padding: rem(10) rem(40);
		font: {
			family: "Roboto";
			size: rem(14);
		}
		&:hover,
		&:focus {
			background: $gray;
		}
	}

	&.btn-right, &.btn-left {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	&.btn-left {
		left: rem(14);
	}

	&.btn-right {
		right: rem(14);
		width: auto !important;
		color: $white;
		font-size: rem(16);
		outline: none;
		text-decoration: none;
		box-shadow: none;

		.spinner {
			top: 0;
			left: 0;
		}
	}

	&.btn-link {
		width: 100%;
		max-width: 100%;
		border-radius: rem(4);
		border: rem(1) solid $white;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: auto;
		min-height: rem(50);

		&:hover, &:focus {
			text-decoration: none;
		}
	}

	&.btn-danger {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: rem(13) rem(20);
		font-size: rem(16);
		line-height: rem(24);
		border-radius: rem(4);
		background-color: #B4474E;
		color: $white;
	}

	&.btn-green, &.btn-red {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: rem(2) rem(8);
		font-size: rem(12);
		line-height: rem(20);
		border-radius: rem(4);
	}

	&.btn-green {
		background-color: #70D58410;
		color: #70D584;
	}

	&.btn-red{
		background-color: #FF585820;
		color: #FF5858;
	}
}
