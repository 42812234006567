//Vars
$primary: "TT Fors", sans-serif;
$fgtl: "FGTL", sans-serif;

$browser-context: 16; // Default

$primary-color: linear-gradient(90deg, rgba(70, 127, 255, 0.20) 0%, rgba(164, 20, 255, 0.20) 100%);
$secondary-color: linear-gradient(90deg, rgba(70, 127, 255, 0.20) 0%, rgba(164, 20, 255, 0.20) 100%);
//$tinned-primary: rgba($primary-color, 0.2);
$secondary-color: #1958fa;
$link-color: blue;
$light-primary: hsl(172, 80%, 37%);
$gray: rgba(0, 0, 0, 0.05);
$form-placeholder-border: #f1f4f8;
$admin-bg: rgba(#dbeafc, 0.2);
$heading-green: #13ac97;
//Colors
$black: #000;
$white: #fff;
$red: #ff0000;
$gold: #e1ba00;
$dark-red: #cc153f;
