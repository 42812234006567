@font-face {
    font-family: "TT Fors";
    src: url("../../public/fonts/tt/TT Fors Light.ttf") format("ttf"),
        url("../../public/fonts/tt/TT Fors Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "TT Fors";
    src: url("../../public/fonts/tt/TT Fors.ttf") format("ttf"),
        url("../../public/fonts/tt/TT Fors.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "TT Fors";
    src: url("../../public/fonts/tt/TT Fors Medium.ttf") format("ttf"),
        url("../../public/fonts/tt/TT Fors Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
	font-family: "TT Fors";
	src: url("../../public/fonts/tt/TT Fors DemiBold.ttf") format("ttf"),
	url("../../public/fonts/tt/TT Fors DemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "TT Fors";
	src: url("../../public/fonts/tt/TT Fors Bold.ttf") format("ttf"),
	url("../../public/fonts/tt/TT Fors Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "TT Fors";
	src: url("../../public/fonts/tt/TT Fors ExtraBold.ttf") format("ttf"),
	url("../../public/fonts/tt/TT Fors ExtraBold.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "TT Fors";
	src: url("../../public/fonts/tt/TT Fors Black.ttf") format("ttf"),
	url("../../public/fonts/tt/TT Fors Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
    font-family: "FGTL";
    src: url("../../public/fonts/fgtl/FGTL.ttf") format("ttf"),
        url("../../public/fonts/fgtl/FGTL.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
